var $class="se2--snackbar-component",$name="snackbarComponent",$path="app/partials/snackbarComponent/index.js",$this={$class,$name,$path,};import shell from 'app/modules/shell';

export default shell.registerComponent($this, ({
  mount, addEventListener,
}) => {
  const handleCloseBtnClick = ({ target }) => {
    target.closest('.snackbar-container').classList.add('invisible');
  };

  mount((element) => {
    const closeBtn = element.querySelector('.close-btn');
    if (closeBtn) addEventListener(closeBtn, 'click', handleCloseBtnClick);
  });
});
